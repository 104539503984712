import styles from './HelpDeskNew.module.scss'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { useContext, useEffect, useState } from 'react'
import api from '../../services/api'
import { useNavigate, useParams } from 'react-router-dom'

import { toast } from 'react-toastify'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { RiArrowGoBackFill } from 'react-icons/ri';
import { BiSupport } from 'react-icons/bi';
import he from 'he';
import { useRef } from 'react';


export default function HelpDeskNew() {
  const [segFirstLoading, setSegFirstLoading] = useState(true)
  const [faqsShow, setFaqsShow] = useState([])
  const [venVal, setVenVal] = useState(null)
  const [catVal, setCatVal] = useState(null)
  const [sesVal, setSesVal] = useState(null)
  const [update, setUpdate] = useState(1);
  const [faqs, setFaqs] = useState([]);
  const [helpsList, setHelpsList] = useState([]);
  const [sessoesList, setSessoesList] = useState([]);
  const [vendasList, setVendasList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fieldValidations, setFieldValidations] = useState({
    venda: true,
    assunto: true,
    mensagem: true,
    telefone: true,
    categoria: true,
    sessao: true
  });
  const selectRef = useRef(null);
  const selectRefCategoria = useRef(null);
  const selectRefVenda = useRef(null);

  const changeCategoriaValue = (newValue) => {
    setValue("categoria", newValue); // Update react-hook-form value
    if (selectRef.current) {
      selectRef.current.value = newValue;
    }
    //trigger("categoria"); // Optionally trigger validation
  };

  const changeSelectValue = (newValue) => {
    setValue("sessao", newValue); // Update react-hook-form value
    if (selectRef.current) {
      selectRef.current.value = newValue;
    }
    //trigger("sessao"); // Optionally trigger validation
  };

  const changeSelectVendaValue = (newValue) => {
    setValue("venda", newValue); // Update react-hook-form value
    if (selectRef.current) {
      selectRef.current.value = newValue;
    }
    //trigger("venda"); // Optionally trigger validation
  };

  const updateFieldValidation = (fieldName, isValid) => {
    setFieldValidations((prevValidations) => ({
      ...prevValidations,
      [fieldName]: isValid,
    }));
  };

  const { seguros } = useParams();

  const isSeguros = seguros == 1;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    trigger,
    formState: { errors },
    } = useForm();

    useEffect(() => {
      setLoading(true);
      getDataVendas();
      getDataCategorias();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [update])

    function handleShowFaq(id){ 
      if(faqsShow.includes(id)){
        let n = [...faqsShow];
        n.splice(n.indexOf(id), 1);
        setFaqsShow(n);
      }else{
        let n = [...faqsShow, id];
        setFaqsShow(n);
      }
     
    }

    async function getDataVendas(){
      await api.post('/shoppinglist')
      .then(res => {
        setVendasList(res.data.retorno);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        console.log(err)
      }).finally(() => {
        if(isSeguros){
          changeSelectVendaValue(vendasList[0]?.id);
        }
      })
      setLoading(false);
  }

  function getDataCategorias() {
    setLoading(true);
  
    api.post('helps')
      .then(response => {
        if (!response.data.sucesso) {
          throw new Error('Request failed');
        }
        setHelpsList(response.data.retorno);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {

        if(isSeguros && segFirstLoading){
          handleChangeCategoria(107)
          changeCategoriaValue(107);
        }

        setLoading(false);
      });
  }
  

  function handleChangeCategoria(idcategoria) {
    setSessoesList([]);
  
    console.log(idcategoria);
  
    if (idcategoria === '' || idcategoria === undefined) {
      return;
    }
  
    setLoading(true);
  
    api.post('/helps/' + 33 + '/' + idcategoria)
      .then(response => {
        if (!response.data.sucesso) {
          throw new Error('Request failed');
        }
        setSessoesList(response.data.retorno);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);

        if(isSeguros && segFirstLoading){
          handleChangeSessao(317)
          changeSelectValue(317)
        }
      });
  }
  
  function handleChangeSessao(idsessao) {
    setFaqs([]);
  
    console.log(idsessao);
  
    if (idsessao === '' || idsessao === undefined) {
      return;
    }
  
    setLoading(true);
  
    api.post('/faqs/' + idsessao)
      .then(response => {
        if (!response.data.sucesso) {
          throw new Error('Request failed');
        }
        setFaqs(response.data.retorno);
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        if(isSeguros && segFirstLoading){
          setSegFirstLoading(false);
          changeSelectValue(317)
        }
        setLoading(false);
      });
  }

  async function sendData(data){
    console.log(fieldValidations);
    setLoading(true);
    const userToken = localStorage.getItem('CITOKEN')

    let response = await api.post('/newhelp/', {
      // idsecao: userToken,
      idsecao: data.sessao,
      assunto: data.assunto,
      mensagem: data.mensagem,
      idvenda: data.venda,
      idcategoria: data.categoria
    })

    if(!response.data.sucesso){
      toast.error(response.data.mensagem);
      setLoading(false);
      return;
    }

    setLoading(false);
    navigate('/helpdesk');
    toast.success(response.data.mensagem);
  }



  return (
    <>

          <>
          { loading ? <div className={styles.loading}><AiOutlineLoading3Quarters/></div> :

          <div className={styles.container}>

              <div className={styles.headerList}>
                <h2><BiSupport/> Nova solicitação</h2>
                <button onClick={() => navigate(-1)}><RiArrowGoBackFill/></button>
              </div>

              <form className={styles.form} onSubmit={handleSubmit(sendData)}>


              <div className={`${styles.inputbox} ${!fieldValidations.venda ? styles.invalid : ''}`}>
                <span>Compra *</span>
                <select ref={selectRefVenda}                 
                {...register("venda", { required: "A compra é obrigatória" })}
                onBlur={() => {
                  trigger("venda").then((isValid) => {
                    updateFieldValidation("venda", isValid);
                  });
                }}
                >
                  {isSeguros ? '' : <option value="">Selecione</option> }
                  {vendasList?.map(c => (
                    <option key={c.id} value={c.id}>
                        {c.nome}
                      </option>
                    ))}
                </select>
              </div>

              {errors.venda && <p className={styles.textDanger}>{errors.venda.message}</p>}

              <div className={`${styles.inputbox} ${!fieldValidations.categoria ? styles.invalid : ''}`}>
                <span>Categoria *</span>
                <select ref={selectRefCategoria}
                  {...register("categoria", { required: "A categoria é obrigatória" })}
                  onBlur={(e) => {
                    trigger("categoria").then((isValid) => {
                      updateFieldValidation("categoria", isValid);
                      handleChangeCategoria(e.target.value);
                    });
                  }}
                >
                  <option value="">Selecione</option>
                  {helpsList?.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.nome}
                    </option>
                  ))}
                </select>
              </div>

              {errors.categoria && <p className={styles.textDanger}>{errors.categoria.message}</p>}

              <div className={`${styles.inputbox} ${!fieldValidations.sessao ? styles.invalid : ''}`}>
                <span>Seção *</span>
                <select ref={selectRef}
                  {...register("sessao", { required: "A seção é obrigatória" })}
                  onBlur={(e) => {
                    trigger("sessao").then((isValid) => {
                      updateFieldValidation("sessao", isValid);
                      handleChangeSessao(e.target.value);       
                    });
                  }} 
                >
                  <option value="">Selecione</option>
                  {sessoesList?.map(c => (
                    <option key={c.id} value={c.id}>
                      {c.nome}
                    </option>
                  ))}
                </select>
              </div>

              {errors.sessao && <p className={styles.textDanger}>{errors.sessao.message}</p>}

              {faqs.length > 0 && 
                <div className={styles.faqs}>
                  <div>
                    <h4>Encontramos algumas informações sobre a sua dúvida:</h4>
                    {faqs.map(f => {
                      return <div className={styles.faqbox}>
                            <div onClick={() => handleShowFaq(f.faqid)} className={styles.faqboxheader}> 
                              {f.faqnome} 
                              { faqsShow.includes(f.faqid) ? <IoMdArrowDropup/> : <IoMdArrowDropdown/>}
                            </div>
                            {faqsShow.includes(f.faqid) &&
                              <div className={styles.faqInfo} dangerouslySetInnerHTML={{ __html: he.decode(f.faqdescricao) }} />
                            }
                      </div>
                      
                    })}
                  </div>
                </div>
              }

              <div className={`${styles.inputbox} ${fieldValidations.assunto ? '' : styles.invalid}`}>
                <span>Assunto *</span>
                <input type="text" name="assunto" placeholder="Assunto"
                {...register("assunto", { required: "O assunto é obrigatório",
                  minLength: {
                    value: 2,
                    message: "Mínimo 2 caracteres",
                  }
                })}
                onKeyUp={() => {
                  trigger("assunto").then((isValid) => {
                    updateFieldValidation('assunto', isValid);
                  });
                }}
                />
              </div>

              {errors.assunto && (
                <small className={styles.textDanger}>{errors.assunto.message}</small>
              )}

              <div className={`${styles.inputbox} ${fieldValidations.mensagem ? '' : styles.invalid}`}>
                <span>Mensagem *</span>
                <textarea  name="mensagem" placeholder="mensagem"
                {...register("mensagem", { required: "A mensagem é obrigatória",
                  minLength: {
                    value: 2,
                    message: "Mínimo 10 caracteres",
                  }
                })}
                onKeyUp={() => {
                  trigger("mensagem").then((isValid) => {
                    updateFieldValidation('mensagem', isValid);
                  });
                }}
                ></textarea>
              </div>

              {errors.mensagem && (
                <small className={styles.textDanger}>{errors.mensagem.message}</small>
              )}

              <button type="submit">Enviar</button>
              </form>
            </div>

          }
          </>        
    </>
  )
}